.App {
  text-align: center;
}

.App-title {
  background-color: #ff5745;
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  justify-content: center;
  height: 410px;
  padding-right: 5px;
  padding-left: 5px;

  h1 {
    margin: 0 0 20px 0;
    color: #fff;
  }

  button.large-button {
    background-color: #2b2734;
  }

  input {
    border: none;
  }
}

.App-title-subtitle {
  margin: 0 auto;
  h2 {
    color: #eee;
    font-size: 0.8em;
    font-weight: normal;
    margin-top: 0px;
    line-height: 140%;
  }
}

.App-get-started {
  margin-top: -10px;
}

.App-start-btn {
  @media only screen and (max-width: 500px) {
    width: 280px;
  }
}

.App-benefits-inner {
  margin: 0 auto;
  display: flex;
  max-width: 1050px;
  @media only screen and (max-width: 720px) {
    flex-direction: column;
  }
}

.App-benefits {
  padding: 30px 0 90px 0;
  @media only screen and (max-width: 720px) {
    padding: 0 0 30px 0;
  }
}

.App-benefit {
  width: 33%;
  margin: 0 25px;
  line-height: 150%;
  font-size: 17px;
  @media only screen and (max-width: 720px) {
      width: 90%;
      margin: 0 auto;
  }
}

.App-benefit-title {
  font-weight: bold;
  font-size: 25px;
  color: #ff5745;
  margin-bottom: 21px;
  @media only screen and (max-width: 720px) {
    margin-bottom: 10px;
    text-align: center;
  }
}

.App-benefit-description {
  @media only screen and (max-width: 720px) {
    margin-bottom: 30px;
  }
}

.App-email-input {
  margin-right: 10px;
  margin-bottom: 20px;
  width: 250px;
  @media only screen and (max-width: 500px) {
    margin-right: 0px;
  }
}

section.App-image {
  padding-top: 0;
  margin-top: -125px;
  @media only screen and (min-width: 1600px) {
    margin-top: -70px;
  }
  @media only screen and (max-width: 1200px) {
    margin-top: -155px;
  }
  @media only screen and (max-width: 500px) {
    margin-top: -92px;
  }
}

.App-product-image {
  margin: 0 auto;
  display: inline-block;

  img {
    width: 71.5%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 0px 50px 0 rgba(0, 0, 0, 0.3);
    @media only screen and (max-width: 500px) {
      width: 90%;
    }
  }
}

.App-bottom-signup {
  background-color: #ff5745;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px 0;

  .App-get-started {
    margin-top: 30px;
  }

  h2 {
    color: #fff;
    margin-bottom: 0;
  }

  button.large-button {
    background-color: #2b2734;
  }  

  input {
    border: none;
  }
}

