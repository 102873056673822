.Survey {
    text-align: left;
}

.Survey-explanation {
    margin-top: 30px;

    p {
        font-size: 1em;
        line-height: 140%;
        font-weight: normal;
    }
}
