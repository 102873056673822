html {
  height: 100%;
  font-size: calc(0.8em + 1vw);
}

#root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #545760;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

section {
  padding: 70px 0;
}

a {
  color: #ff5745;
  text-decoration: none;
  outline: 0;
}

h1 {
  margin: 0;
  padding: 0;
  font-size: 1.6em;
  color: #363142;
}

h2 {
  margin: 0;
  padding: 0;
  font-size: 1.4em;
  color: #363142;
}

h3 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-weight: normal;
  color: #666;
}

.button {
  outline: 0;
  background-color: #ff5745;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  border: none;
  cursor: pointer;
}

.large-button {
  padding: 15px 50px;
  font-weight: bold;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input {   
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: solid 1px #ccc;
  padding: 14px;
  outline: 0;
  border-radius: 4px; 
}

input.large-input {
  font-size: 16px;
}

select {
  -webkit-appearance: button;
  -moz-appearance: button;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-padding-end: 20px;
  -moz-padding-end: 20px;
  -webkit-padding-start: 2px;
  -moz-padding-start: 2px;
  background-color: white; /* fallback color if gradients are not supported */
  background-position: center right;
  border: solid 1px #ccc;
  border-radius: 4px;
  padding: 14px;
  outline: 0;
  font-size: 16px;
  color: #757575;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.section-inner {
  max-width: 950px;
}

section {
  h2 {
    margin-bottom: 50px;
  }
}

.Simple-page {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  min-height: 490px;
  margin: 0px 50px;
  
  h2 {
    margin-bottom: 10px;
  }
}