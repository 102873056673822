

.User-title {
    margin: 0 auto;
    padding: 10px;
}


.User-name-input {
    margin-bottom: 10px;
    width: 300px;
}

.User-teamsize-input {
    margin-bottom: 10px;
    width: 330px;
}


.User-next-button {
    width: 328px;
    margin-top: 5px;
}

.User-input {
    margin-top: 20px;
}