.Home-wrapper {
    height: 100%;
}

.Home-inner {
    min-height: 78%;
}

.Footer {
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    padding: 40px 50px;
    border-top: solid 1px #eee;
    @media only screen and (max-width: 910px) {
        flex-direction: column;
    }
}

.Footer-copyright {
    text-align: center;
    @media only screen and (max-width: 910px) {
        margin-bottom: 25px;
    }
}

.Footer-links {
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 610px) {
        flex-direction: column;
        margin: 0 auto;
        text-align: center;
    }
}


.Header {
    background-color: #ff5745;
    padding: 20px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        color: #fff;
        font-weight: bold;
    }

    @media only screen and (max-width: 500px) {
        justify-content: space-around;
    }
}

.Login {
    font-size: 16px;
    @media only screen and (max-width: 500px) {
        display: none;
    }
}

.Header-logo {
    width: 190px;
    height: 50px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('/switch-logo.png');
  }
  